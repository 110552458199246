<template>
  <div class="row">
    <div class="col-12">
      <DataTables
        :url="url"
        @loading="setLoading"
        :columns_list="columns_list"
      />
    </div>
  </div>
</template>

<script>
import DataTables from "@/components/base/DataTables.vue";

export default {
  name: "SellDetailsList",
  components: {
    DataTables
  },
  data() {
    return {
      columns_list: [
        {
          key: "order_id",
          name: "Szczegóły",
          filter: "btn"
        },
        {
          key: "number",
          name: "Numer",
          type: "text",
          sortable: true
        },
        {
          key: "date",
          name: "Data zamówienia",
          type: "date",
          sortable: true
        },
        {
          key: "notice",
          name: "Typ",
          type: "text",
          sortable: true
        }
      ]
    };
  }
};
</script>
